import React, { useEffect, useState } from 'react'
import {
  Routes,
  BrowserRouter,
  Route,
  Navigate,
} from "react-router-dom"
import Login from './components/Login/Login'
import List from './components/list/List';
import Register from './components/register/Register';
import Update from './components/update/Update';
import InitializeGoogleAnalytics from './analytics/Analytics';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    InitializeGoogleAnalytics()
    console.log("this is done")
  }, [])
  
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/register" element={<Register  setAuth = {setIsAuthenticated}/>} />
        <Route path="/add" element={isAuthenticated ?  <Login /> : <Navigate to="/register" />} />
        <Route path='/' element={isAuthenticated ? <List /> : <Navigate to="/register" />} />
        <Route path="/update/:id" element= {<Update /> } />
      </Routes>


    </BrowserRouter>

  );
}
export default App
