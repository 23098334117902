import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './update.css'
const Update = () => {
    const location = useLocation()
    const id = location.pathname.split("/")[2]
    const [data, setData] = useState({})
    const [nameType, setNameType] = useState("")
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [source, setSource] = useState("")
    const [status, setStatus] = useState(undefined)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`https://emaildbapi.onrender.com/user/${id}`)
                console.log(res.data)
                setData(res.data)
                setNameType(res.data.position)
                setEmail(res.data.email)
                setName(res.data.name)
                setSource(res.data.source)
                

            } catch (error) {
                console.log(error)

            }
        }
        fetchData()
    }, [id])

    const handleChange = (e) => {
        if (e.target.name === "cars") {
            setNameType(e.target.value)
        } else if (e.target.name === "source") {
            setSource(e.target.value)
        }



    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleChangeName = (e) => {
        setName(e.target.value)
    }

    const handleClick = async () => {
        const data = {
            "email": email,
            "name": name,
            "position": nameType,
            "source": source

        }
        
        try {
            const res = await axios.put(`https://emaildbapi.onrender.com/${id}`, data)
            setStatus("succes")
            console.log(res.response)

        } catch (error) {
            setStatus("erreur")
            console.log(error.response.status)

        }
        window.location.reload();
    }
    return (
        <div className="add">
            <Link to={"/"} style={{ color: 'inherit', textDecoration: 'none' }}>
                <h1 className="header">Acceuil</h1>
            </Link>

            <div className="addContainer">
                <div className="email">
                    <span className="emailTitle">Adresse Email </span>
                    <input
                        type="email"
                        className="emailAdresse"
                        placeholder="example@gmail.com"
                        value={email}
                        onChange={handleChangeEmail}
                    />
                </div>
                <div className="name">
                    <span className="nameTitle">Nom </span>
                    <input type="text" className="emailAdresse" placeholder="Nom" onChange={handleChangeName} value={name} />
                </div>
                <div className="name">
                    <span className="nameTitle">Position </span>
                    <select name="cars" value={nameType} className="selectOptions" onChange={(e) => handleChange(e)}  >
                        <option value="Recuiter">Recuiter</option>
                        <option value="student">student</option>
                        <option value="Manager">Manager</option>
                        <option value="friend">friend</option>
                        <option value="family">family</option>
                    </select>
                </div>
                <div className="name">
                    <span className="nameTitle">Source </span>
                    <select name="source" value={source} className="selectOptions" onChange={(e) => handleChange(e)}>
                        <option value="Linkedin">Linkedin</option>
                        <option value="Entretien">Entretien</option>
                        <option value="Encadrant">Encadrant</option>
                        <option value="ListeMarseille">ListeMarseille</option>
                        <option value="FM6">FM6</option>
                    </select>
                </div>
                <span className="status">{status}</span>

                <button className="SubmitButton" onClick={handleClick}>Envoyer</button>
            </div>
        </div>
    )
}

export default Update
