import { useEffect, useState } from "react"
import "./list.css"
import axios from "axios"

import { Link, useNavigate } from "react-router-dom";

const List = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState([])
    const [controle, setControle] = useState(true)
    const [count, setCount] = useState(0)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get("https://emaildbapi.onrender.com/")
                setEmail(res.data)

            } catch (error) {
                console.log(error)
            }
        }
        fetchData()
        setControle(true)
        console.log(email)

    }, [controle])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get("https://emaildbapi.onrender.com/count")
                setCount(res.data)


            } catch (error) {
                console.log(error)

            }

        }
        fetchData()
        console.log(count)



    }, [controle])

    const handleClick = async (email) => {
        try {
            const res = await axios.delete(`https://emaildbapi.onrender.com/${email._id}`)
            setControle(false)
            alert("the email has been deleted")

        } catch (error) {
            alert("there is a problem")

        }

    }

    const handleNavigate = () => {
        navigate("/add")
    }
    console.log(email)





    return (
        <div className="list">
            <div className="listContainer">

                <h1 className="listTitle">Liste des mails</h1>
                <span className="countEmail"> le nombre totale des <br /> mails est : {count} </span>

                {
                    email.map((email, i) => (
                        <div className="listContent" key={email._id}>
                            <span className="listEmail">
                                {email.email}
                            </span>
                            <span className="listNom">
                                {email.name}
                            </span>
                            <span className="listPosition">
                                {email.position}
                            </span>
                            <div className="listButton">

                                <button>
                                    <Link to={`/update/${email._id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                        Modifier
                                    </Link>
                                </button>


                                <button onClick={() => handleClick(email)}>Supprimer</button>

                            </div>
                        </div>

                    ))
                }


                <button className="addButton" onClick={handleNavigate} >Ajouter un email</button>




            </div>


        </div>
    )
}

export default List
