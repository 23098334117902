import React, { useState } from "react";
import axios from "axios"
import { Link } from "react-router-dom"

import "./login.css";
export const Login = () => {
  const [nameType, setNameType] = useState('Recuiter')
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [source, setSource] = useState("ListeMarseille")
  const [status, setStatus] = useState(undefined)


  const handleChange = (e) => {
    if (e.target.name === "cars") {
      setNameType(e.target.value)
    } else if (e.target.name === "source") {
      setSource(e.target.value)
    }



  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }
  const handleChangeName = (e) => {
    setName(e.target.value)
  }
  const handleClick = async () => {
    const data = {
      "email": email,
      "name": name,
      "position": nameType,
      "source": source

    }
    console.log(data)
    try {
      const res = await axios.post("https://emaildbapi.onrender.com/", data)
      setStatus("succes")
      console.log(res.response)

    } catch (error) {
      setStatus("erreur")
      console.log(error.response.status)

    }
    window.location.reload();
  }

  return (
    <div className="add">
      <Link to={"/"}  style={{color : 'inherit', textDecoration : 'none'}}>
        <h1 className="header">Acceuil</h1>
      </Link>

      <div className="addContainer">
        <div className="email">
          <span className="emailTitle">Adresse Email </span>
          <input
            type="email"
            className="emailAdresse"
            placeholder="example@gmail.com"
            onChange={handleChangeEmail}
          />
        </div>
        <div className="name">
          <span className="nameTitle">Nom </span>
          <input type="text" className="emailAdresse" placeholder="Nom" onChange={handleChangeName} />
        </div>
        <div className="name">
          <span className="nameTitle">Position </span>
          <select name="cars" defaultValue={nameType} className="selectOptions" onChange={(e) => handleChange(e)}>
            <option value="Recuiter">Recuiter</option>
            <option value="student">student</option>
            <option value="Manager">Manager</option>
            <option value="friend">friend</option>
            <option value="family">family</option>
          </select>
        </div>
        <div className="name">
          <span className="nameTitle">Source </span>
          <select name="source" defaultValue={source} className="selectOptions" onChange={(e) => handleChange(e)}>
            <option value="Linkedin">Linkedin</option>
            <option value="Entretien">Entretien</option>
            <option value="Encadrant">Encadrant</option>
            <option value="ListeMarseille">ListeMarseille</option>
            <option value="FM6">FM6</option>
          </select>
        </div>
        <span className="status">{status}</span>

        <button className="SubmitButton" onClick={handleClick}>Envoyer</button>
      </div>
    </div>
  );
};
export default Login
